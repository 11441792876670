import classes from './MealsSummary.module.css';

const MealsSummary = () => {
  return (
    <section className={classes.summary}>
      <h2>Produse electrice</h2>
      <p>
        O gamă largă de produse pentru casa ta.
      </p>
      <p>
        Toate produsele sunt de calitate superioară direct de la producător.
      </p>
    </section>
  );
};

export default MealsSummary;
